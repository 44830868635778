import GitHubIcon from "@mui/icons-material/GitHub";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import * as Material from "@mui/material";
import { IconButton, Link, Toolbar, Typography } from "@mui/material";
import React from "react";
import { contact } from "../text/contact";
export class AppBar extends React.Component {
  render() {
    return (
      <Material.AppBar elevation={0} color='transparent' position='static'>
        <Toolbar>
          <Typography variant='h5' sx={{ flexGrow: 1 }}></Typography>
          {contact.github && (
            <Link color='inherit' href={contact.github} target='_blank'>
              <IconButton color='inherit'>
                <GitHubIcon />
              </IconButton>
            </Link>
          )}
          <Link color='inherit' href={contact.linkedIn} target='_blank'>
            <IconButton color='inherit'>
              <LinkedInIcon />
            </IconButton>
          </Link>
        </Toolbar>
      </Material.AppBar>
    );
  }
}
