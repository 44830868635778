export const projects = [
  {
    title: "Mini Weather Forecaster | Python",
    role: "Author",
    description: `Scripted a function in Python that provided regular weather updates through text messages. Used API from "OnlineWeatherMaps" to import weather data into python.`,
    // url: "https://github.com/kaleido-public/django-client-framework",
    keywords: ["*"],
  },
  {
    title: "Autonomous Robot | LEGO MINDSTORMS",
    role: "Author",
    description: `Successfully designed, programmed & troubleshot the robot to perform retrieving and tracking tasks, received 3rd place despite of having one month to prepare.`,
    // url: "https://github.com/yuliswe/react-appevent-redux",
    keywords: ["*"],
  },
];
