export type MyStory = {
  title: string;
  keywords: string[];
  text: string;
};
export const stories: MyStory[] = [
  {
    title: "...QA Automation",
    keywords: ["*"],
    text: `My passion for Quality Assurance (QA) lies in the satisfaction of seeing a seamless interaction between user and technology, where every element functions as intended and the user experience is defect-free. My foray into QA at Manulife was a revelation; utilizing behavior-driven development (BDD) to animate web pages autonomously was like watching magic unfold—web elements came to life, mimicking user interactions flawlessly. This initial experience opened my eyes to the profound capabilities of QA in enhancing user interfaces and interactions. From designing autonomous tests at Imagine Communications to perfecting critical UI components like the save button, my journey has been propelled by a zeal for tackling complex challenges. At Teranet CMS, I delved deeper into regression testing and optimizing execution times. QA, for me, is an intricate puzzle, each piece representing a challenge that I'm eager to fit into the larger picture of perfect functionality.`,
  },
  {
    title: "...Python",
    keywords: ["*"],
    text: `My journey with Python began in my first year and has evolved into a steadfast partnership. Its elegance and efficiency have made it my go-to for creating impactful projects, like the weather reporter which could be used as a handy help towards my daily life. As a Teaching Assistant for courses such as APS1070 (Foundations to Data Analytics and Machine Learning) and CSC108 (Introduction to Computer Programming) at UofT, I've not only deepened my expertise but have also guided students through Python's potential in solving real-world problems. This role has been incredibly rewarding, as I've witnessed the empowerment it gives to new programmers. Python's prominence in machine learning and my academic pursuits have solidified its role as a cornerstone of my professional and educational endeavors, as I strive to leverage its power in the QA/SDE landscape.`,
  },
];
export default stories;
