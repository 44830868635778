export default [
  {
    title: "Candidate for Master of Engineering",
    company: "University of Toronto",
    location: "Ontario",
    date: "",
    alwayShow: true,
    keywords: [],
    bulletPoints: [
      {
        text: "Major in Electrical & Computer Engineering",
        date: "2023 - 2025",
      },
    ],
  },
  {
    title: "Bachelor of Applied Science",
    company: "University of Waterloo",
    location: "Ontario",
    date: "",
    alwayShow: true,
    keywords: [],
    bulletPoints: [
      {
        text: "Major in Nanotechnology Engineering (Co-op)",
        date: "2018 - 2023",
      },
      {
        text: "Awardee of President's Research Award (undergrad researcher)",
        date: "Sept 2019",
      },
      {
        text: "Awardee of President's Scholarship of Distinction",
        date: "July 2019",
      },
    ],
  },
] as const;
