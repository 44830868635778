export const skillsSummary = [
  "Python",
  "HTML/CSS",
  "TypeScript",
  "React",
  "Nodejs",
  "C#",
  "Test-Driven Development",
  "Selenium",
];
