import { Box, Link, Stack, Typography } from "@mui/material";
import React from "react";
import { mediaQuery } from "../AppContext";
import { contact } from "../text/contact";
type _Props = {
  jobTitle: string;
  mediaQuery?: typeof mediaQuery;
  websiteUrl: URL;
};
export class ResumeHeader extends React.Component<_Props> {
  get smallScreen(): boolean {
    return this.props.mediaQuery?.sm || false;
  }

  get companyUrlBare(): string {
    return (
      "https://" + this.props.websiteUrl.host + this.props.websiteUrl.pathname
    );
  }

  render() {
    return (
      <React.Fragment>
        <Stack>
          <Box ml={2}>
            <Typography
              variant='h5'
              sx={{
                textAlign: "center",
              }}>
              <b>Houfu Chen</b>
            </Typography>
            <Box>
              <Typography sx={{ textAlign: "center" }}>
                {this.props.jobTitle}
              </Typography>
            </Box>
          </Box>
          <Stack direction='row' justifyContent='center'>
            <Box
              display='flex'
              alignItems='center'
              flexWrap='wrap'
              justifyContent='center'>
              {[
                <Link
                  target='_blank'
                  underline='hover'
                  href={`mailto:${contact.email}`}>
                  {contact.email}
                </Link>,
                <Link target='_blank' underline='hover' href={contact.linkedIn}>
                  LinkedIn
                </Link>,
                <Link target='_blank' underline='hover' href={contact.website}>
                  Website
                </Link>,
                <Link
                  target='_blank'
                  type='tel'
                  underline='hover'
                  href={`tel:${contact.tel}`}>
                  {contact.tel}
                </Link>,
                <Typography>{contact.address}</Typography>,
              ].map((x, i) => (
                <React.Fragment key={i}>
                  {i > 0 && <Box mx={1}>&#x2022;</Box>} {x}
                </React.Fragment>
              ))}
            </Box>
          </Stack>
        </Stack>
      </React.Fragment>
    );
  }
}
