import type React from "react";

type Employment = {
  title: string;
  company: string;
  location: string;
  date: string;
  keywords: string[];
  highlight: string[];
  bulletPoints: {
    text: React.ReactNode;
    keywords: string[];
  }[];
};
export const employment: Employment[] = [
  {
    title: "CarPlay Wifi Engineer",
    company: "Ford Motor",
    location: "Oakville, ON",
    date: "May 2022 - Aug 2022",
    keywords: ["*"],
    highlight: ["Python", "Linux", "Software Testing", "Communication"],
    bulletPoints: [
      {
        text: (
          <>
            Implemented daily performance testing and optimization strategies
            with <b>Python</b> for the Transmission Control Unit, enhancing
            system efficiency and reliability across diverse scenarios.,
          </>
        ),
        keywords: ["*"],
      },
      {
        text: (
          <>
            Troubleshooted over 40 network and system issues in the testing
            environment with <b>Linux networking</b> and system administration
            knowledge.
          </>
        ),
        keywords: ["*"],
      },
      {
        text: (
          <>
            Created detailed documentation for system performance testing and
            troubleshooting, greatly improved team collaboration workflow by
            inventing a centralized knowledge base.
          </>
        ),
        keywords: ["*"],
      },
    ],
  },
  {
    title: "Software Developer",
    company: "Imagine Communications Corp",
    location: "ON",
    date: "Jan 2021 - April 2021",
    keywords: [],
    highlight: ["Test-Driven Development", "TypeScript", "MySQL"],
    bulletPoints: [
      {
        text: <>Developed and refined critical UI components with <b>TypeScript</b> and <b>React</b>, enhancing user interaction and system reliability.</>,
        keywords: ["*"],
      },
      {
        text: (
          <>
            Developed software with <b>TypeScript</b> and <b>MySQL</b> for
            monitoring production server, including health checks and alerts.
          </>
        ),
        keywords: [],
      },
      {
        text: "Automated testing of web application using TypeScript by writing scripts to simulate user interactions.",
        keywords: [],
      },
    ],
  },
  {
    title: "QA Automation Specialist",
    company: "Teranet CMS",
    location: "ON",
    date: "Sept 2020 - Dec 2020",
    keywords: ["*"],
    highlight: ["C#", "Selenium"],
    bulletPoints: [
      {
        text: <>Enhanced UI feature reliability and test suite efficacy by refining automated test scripts and troubleshooting critical components, such as save functionality, ensuring smooth feature integration and user experience.</>,
        keywords: ["*"],
      },
      {
        text: (
          <>
            Experienced in <b>C#</b> and <b>Selenium</b> for automated testing,
            with a focus on high-request-volume web applications.
          </>
        ),
        keywords: ["*"],
      },
      {
        text: "Specialized in test suites optimization, reducing test execution time by 50% while improving test coverage.",
        keywords: ["*"],
      },
    ],
  },
  {
    title: "Software Engineer",
    company: "Manulife",
    location: "Waterloo, ON",
    date: "Jan 2020 - April 2020",
    keywords: ["*"],
    highlight: [
      "React",
      "JavaScript",
      "HTML",
      "CSS",
      "Software Quality Assurance",
    ],
    bulletPoints: [
      {
        text: (
          <>
            Expertise in test driven development with with <b>React</b>,{" "}
            <b>JavaScript (ES6)</b>, <b>HTML</b>, and <b>CSS</b>, with legacy
            browser support such as IE9.
          </>
        ),
        keywords: ["*"],
      },
      {
        text: "Troubleshoot software bugs resolving 40+ SonarQube issues. Improved test suites to achieve 98%+ code coverage, resulting in improved technical health of the entire code base.",
        keywords: ["*"],
      },
      {
        text: "Independently led development of new features, and demonstrated completed features during organization-wide meetings with other developers, business analysts, stakeholders, and product owners.",
        keywords: ["*"],
      },
    ],
  },
];

export default employment;
