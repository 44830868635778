export const interests = [
  {
    title: "Software Quality Assurance",
    keywords: ["react"],
  },
  {
    title: "React",
    keywords: ["react"],
  },
  {
    title: "HTML/CSS",
    keywords: ["html", "css"],
  },
  {
    title: "Selenium",
    keywords: ["selenium"],
  },
  {
    title: "Python",
    keywords: ["python"],
  },
  {
    title: "C#",
    keywords: ["c#"],
  },
  {
    title: "Test-Driven Development",
    keywords: ["tdd"],
  },
  {
    title: "JavaScript/NodeJS",
    keywords: ["javascript", "nodejs"],
  },
  {
    title: "Linux/Network",
    keywords: ["networking"],
  },
  {
    title: "Communication",
    keywords: ["communication"],
  },
  {
    title: "Research",
    keywords: ["research"],
  },
];
export const allKeywords = new Set<string>();
for (const i of interests) {
  for (const keyword of i.keywords) {
    allKeywords.add(keyword);
  }
}

export default interests;
