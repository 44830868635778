export const contact = {
  github: "",
  linkedIn: "https://www.linkedin.com/in/davidchen0702/",
  email: "houfu.chen@mail.utoronto.ca",
  website: "https://houfuchen.com",
  tel: "(647) 633-9933",
  address: "Toronto, ON, Canada",
  name: "Houfu Chen",
  jobTitle: "Software Developer",
};
