import { Typography } from "@mui/material";
import React from "react";
import { site } from "../text/site";

export class MyCareerSoFar extends React.Component {
  render() {
    return (
      <React.Fragment>
        <Typography variant='h4'>My Career So Far</Typography>
        <Typography variant='body2'>{site.careerSoFar}</Typography>
      </React.Fragment>
    );
  }
}
