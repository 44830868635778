import { Box, Typography } from "@mui/material";
import React from "react";
import { site } from "../text/site";

export class MyCareerGoal extends React.Component {
  render() {
    return (
      <Box>
        <Typography variant='h4'>My Career Goal</Typography>
        <Typography variant='body2'>{site.myCareerGoal}</Typography>
      </Box>
    );
  }
}
